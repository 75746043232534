import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';  // Import useTranslation hook
import emailjs from 'emailjs-com';
import './Contact.css';

const Contact = () => {
  const [emailSent, setEmailSent] = useState(false);
  const { t } = useTranslation();  // Use the translation function

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_90l4vik', 'template_czkernq', e.target, 'info@avermass.de')
      .then(
        (result) => {
          setEmailSent(true);
        },
        (error) => {
          console.error(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div className="contact">
      <h1 style={{ textAlign: "center" }}>{t('contactUs')}</h1>  {/* Translation for 'Contact Us' */}
      <div className="contact-container">
        <div style={{ textAlign: "center" }} className="contact-info">
          <p>{t('contactMessage')}</p>  {/* Translation for the message */}
          <p>{t('phone')}: +49 15213500281</p>  {/* Translation for 'Phone' */}
          <p>{t('address')}: Andreas Kasperbauer Straße 10, 85540 Haar, Munich , Germany</p>  {/* Translation for 'Address' */}
          <p>
        <a href="https://forms.gle/iUGf2L4k2NxSWqpB7" target="_blank" rel="noopener noreferrer">
          {t('Contact Form')}
        </a>
      </p> </div> </div> </div>
       
  );
};

export default Contact;
