import React from 'react';
import { useTranslation } from 'react-i18next';  // Import useTranslation hook
import './Home.css';
import HomePage from '../images/HomePage.png';

const Home = () => {
  const { t } = useTranslation();  // Use the translation function

  return (
    <div className="Home">
      <header className="hero-section">
        <div className="hero-content">
          <h1>{t('empoweringAISolutions')}</h1>  {/* Translation for the header text */}
          <p>{t('vermaDescription')}</p>  {/* Translation for VERMA GmbH description */}
          <a href="/services" className="btn-primary">{t('exploreOurServices')}</a>  {/* Translation for button text */}
        </div>
      </header>

      <section className="about-us">
        <h2 style={{ textAlign: "center" }}>{t('aboutVerma')}</h2>  {/* Translation for 'About VERMA GmbH' */}
        <p>{t('aboutDescription')}</p>  {/* Translation for 'Founded in 2019, VERMA GmbH...' */}
      </section>
      <img   src={HomePage}   alt="Skilled Immigration Process"   style={{ display: "block", margin: "0 auto", maxWidth: "100%", height: "auto" }} />


      <section className="mission">
        <h2 style={{ textAlign: "center" }}>{t('ourMission')}</h2>  {/* Translation for 'Our Mission' */}
        <p>{t('missionDescription')}</p>  {/* Translation for mission description */}
      </section>
    </div>
  );
};

export default Home;
