// SkilledProfessionals.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './skilledprofessionals.css';
import { useNavigate } from 'react-router-dom'; // Import navigation hook

// Import images
import healthcareImage from '../images/healthcare.png';
import itEngineeringImage from '../images/it-engineering.png';
import skilledImmigrationImage from '../images/skilled-immigration.png';

const SkilledProfessionals = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate(); // Initialize navigation


  return (
    <div className="skilled-workers">
      {/* Language Toggle */}
      <header className="hero-section">
        <h1>{t('skilledprofessionals.title')}</h1>
        <p>{t('skilledprofessionals.subtitle')}</p>
      </header>


      <section className="intro">
        
        <p>{t('skilledprofessionals.introText')}</p>
      </section>

      <section className="industry-sectors">
        <h2>{t('skilledprofessionals.sectorsTitle')}</h2>
        <div className="sector-grid">
          <div className="sector-card">
            <img src={healthcareImage} alt="Healthcare" />
            <h3>{t('skilledprofessionals.healthcareTitle')}</h3>
            <p>{t('skilledprofessionals.healthcareDesc')}</p>
          </div>
          <div className="sector-card">
            <img src={itEngineeringImage} alt="IT & Engineering" />
            <h3>{t('skilledprofessionals.itTitle')}</h3>
            <p>{t('skilledprofessionals.itDesc')}</p>
          </div>
          <p>{t('skilledprofessionals.industry')}</p>
        </div>
      </section>
<section className="SupportedIndustry">

<h2>{t('skilledprofessionals.SupportedIndustryTitle')}</h2>
<h2>{t('skilledprofessionals.SupportedIndustryDesc')}</h2>
<p>{t('skilledprofessionals.SupportedIndustry')}</p>
<h4>{t('skilledprofessionals.SupportedIndustry1')}</h4>
<div className="industry-content">
<p>{t('skilledprofessionals.SupportedIndustry2')}</p>

<p>{t('skilledprofessionals.SupportedIndustry3')}</p>
<p>{t('skilledprofessionals.SupportedIndustry4')}</p>
<p>{t('skilledprofessionals.SupportedIndustry5')}</p>
</div>
<h2>{t('skilledprofessionals.SupportedIndustry10')}</h2>
<p>{t('skilledprofessionals.SupportedIndustry11')}</p>
<h4>{t('skilledprofessionals.SupportedIndustry12')}</h4>
<div className="industry-content">
  
  <p>{t('skilledprofessionals.SupportedIndustry13')}</p>
  <p>{t('skilledprofessionals.SupportedIndustry14')}</p>
  <p>{t('skilledprofessionals.SupportedIndustry15')}</p>
</div>

<h2>{t('skilledprofessionals.SupportedIndustry20')}</h2>
<p>{t('skilledprofessionals.SupportedIndustry21')}</p>
<h4>{t('skilledprofessionals.SupportedIndustry22')}</h4>
<div className="industry-content">
<p>{t('skilledprofessionals.SupportedIndustry23')}</p>
<p>{t('skilledprofessionals.SupportedIndustry24')}</p>
<p>{t('skilledprofessionals.SupportedIndustry25')}</p>
</div>
</section>

<section className="why-choose">
  <h2>{t('skilledprofessionals.whyChooseTitle')}</h2>
  <ul className="why-choose-list">
    <li>{t('skilledprofessionals.whyChoose1')}</li>
    <li>{t('skilledprofessionals.whyChoose2')}</li>
    <li>{t('skilledprofessionals.whyChoose3')}</li>
    <li>{t('skilledprofessionals.whyChoose4')}</li>
    <li>{t('skilledprofessionals.whyChoose5')}</li>
  </ul>
</section>


<section className="process">
  <h2>{t('skilledprofessionals.processTitle')}</h2>
  <p>{t('skilledprofessionals.processDesc')}</p>
  
  <h2>{t('skilledprofessionals.processTitleDetails')}</h2>
  <ul className="process-list">
    <li>{t('skilledprofessionals.processTitleDetails1')}</li>
    <li>{t('skilledprofessionals.processTitleDetails2')}</li>
    <li>{t('skilledprofessionals.processTitleDetails3')}</li>
    <li>{t('skilledprofessionals.processTitleDetails4')}</li>
    <li>{t('skilledprofessionals.processTitleDetails5')}</li>
  </ul>

  <img src={skilledImmigrationImage} alt="Skilled Immigration Process" />
</section>


<section className="cta">
      <h2>{t('skilledprofessionals.ctaTitle')}</h2>
      <p>{t('skilledprofessionals.ctaDesc')}</p>
      <button className="cta-button" onClick={() => navigate('/contact')}>
        {t('skilledprofessionals.contactUs')}
      </button>
    </section>
    </div>
  );
};

export default SkilledProfessionals;