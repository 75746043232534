import React from 'react';
import { useTranslation } from 'react-i18next';
import './Products.css';

// Import images directly
import BiometricFace from '../images/Biometric-face.jpg';
import CCTV from '../images/cctv.jpg';
import Services from '../images/services.jpg';
import ScrumBot from '../images/scrumbot.png';

const Products = () => {
  const { t } = useTranslation();

  return (
    <div className="products">
      <h1 style={{ textAlign: "center" }}>{t('ourProducts')}</h1>
      <div className="product-list">
        <div className="product-item">
          <img src={BiometricFace} alt={t('digiPass')} />
          <h3>{t('digiPass')}</h3>
          <p>{t('digiPassDescription')}</p>
        </div>
        <div className="product-item">
          <img src={CCTV} alt={t('computerVision')} />
          <h3>{t('computerVision')}</h3>
          <p>{t('cctvIntelligenceDescription')}</p>
        </div>
        <div className="product-item">
          <img src={Services} alt={t('jobAutomation')} />
          <h3>{t('jobAutomation')}</h3>
          <p>{t('jobAutomationDescription')}</p>
        </div>
        <div className="product-item">
          <img src={ScrumBot} alt={t('ScrumBotandHotelAssistant')} />
          <h3>{t('ScrumBotandHotelAssistant')}</h3>
          <p>{t('ScrumBotandHotelAssistantDescription')}</p>
        </div>
      </div>
    </div>
  );
};

export default Products;
